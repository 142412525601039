import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_2 = { class: "d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2" }
const _hoisted_3 = { class: "flex-grow-1 my-lg-0 my-2 me-2" }
const _hoisted_4 = { class: "text-gray-800 fw-bolder fs-6" }
const _hoisted_5 = {
  key: 0,
  class: "text-gray-600 fw-bolder d-block pt-1"
}
const _hoisted_6 = {
  key: 1,
  class: "text-gray-600 fw-bolder d-block pt-1"
}
const _hoisted_7 = { class: "text-gray-600 fw-bolder d-block pt-1" }
const _hoisted_8 = { class: "text-gray-600 fw-bolder d-block pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "mb-7 d-flex align-items-sm-center col-xl-6",
      key: index
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "symbol-label",
          style: _normalizeStyle(`background-image: url(${_ctx.image});`)
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title ? _ctx.title : "Unavailable"), 1),
          (_ctx.thirdHeader != '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.thirdHeader ? _ctx.thirdHeader : "Brand") + ": " + _toDisplayString(item.brand ? item.brand : "Unavailable"), 1))
            : _createCommentVNode("", true),
          (_ctx.fourtHeader != '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.fourtHeader ? _ctx.fourtHeader : "Model") + ": " + _toDisplayString(item.model ? item.model : "Unavailable"), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.firstHeader ? _ctx.firstHeader : "Serial Number") + ": " + _toDisplayString(item.serialNumber ? item.serialNumber : ""), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.secondHeader ? _ctx.secondHeader : "Value") + ": ₦" + _toDisplayString(item.value ? _ctx.addCommaToNumberString(item.value) : "0"), 1)
        ])
      ])
    ]))
  }), 128))
}